<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"
			:is_item="true"

			@change="getData"
			@click="getData"
			@toItem="onItem"
		>
			<select
				slot="add"
				v-model="search.virtual_code"
				class="pa-5-10 mr-10"

				@change="getSearch(1)"
			>
				<option value="">가상계좌</option>
				<template
					v-for="(code, index) in codes.P006.items"
				>
					<option
						:key="code.total_code + '_' + index"
						:value="code.code_value"
					>{{ code.code_name }}</option>
				</template>
			</select>
		</Search>

		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<div class="full-width">
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="150px" />
						<col width="150px" />
						<col width="150px" />
						<col width="150px" />

						<col width="180px" />
						<col width="auto" />
						<col width="150px" />
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								type="checkbox"
							/>
						</th>
						<th>가상계좌 구분</th>
						<th>가상계좌 명</th>
						<th>가상계좌 가입 ID</th>
						<th>출금 수수료</th>

						<th>사용여부</th>
						<th>등록일</th>
						<th>가상계좌 정보</th>
					</tr>
					</thead>
					<tbody
						v-if="items.length > 0"
					>
					<tr
						v-for="item in items"
						:key="item.uid"
					>
						<td>
							<input
								type="checkbox"
							/>
						</td>
						<td>{{ item.type_name }}</td>
						<td>{{ item.virtual_name}}</td>
						<td>{{ item.mid }}</td>
						<td>{{ item.virtual_fee | makeComma }}원</td>

						<td
							class="full-height"
						>
							<div
								class=" flex-row justify-center"
							>
								<v-icon
									class="pa-5"
									:class="item.is_use == 1 ? 'bg-green color-white' : 'btn-default' "
									@click="item.is_use = 1; postUpdate(item)"
								>mdi mdi-power-plug</v-icon>
								<v-icon
									class="pa-5 "
									:class="item.is_use != 1 ? 'bg-red color-white' : 'btn-default' "
									@click="item.is_use = 0; postUpdate(item)"
								>mdi mdi-power-plug-off</v-icon>

								<v-icon
									class="pa-5 bg-red color-white ml-10"
									@click="onItemDelete(item)"
								>mdi mdi-delete</v-icon>
							</div>
						</td>
						<td>{{ item.wDate }}</td>
						<td>
							<button
								class="bg-identify pa-5-10"
								@click="toList(item)"
							>가상계좌 정보</button>
						</td>
					</tr>
					</tbody>
				</table>

				<Empty
					v-if="!items.length"
				></Empty>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
		</div>

	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'VirtualList'
	, props: ['user', 'codes']
	, components: {Search, Pagination, Empty}
	, data: function(){
		return {
			program: {
				name: '가상계좌 목록'
				, top: true
				, title: true
				, bottom: false
			}
			, search: {
				virtual_code: ''
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'account_id'
				, is_use: ''
			}
			,search_option:{

				is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'account_id'}
					,{ name: '이름', column: 'account_name'}
				]
				,select: [
					{ name: '사용 여부', column: 'is_use', items: [
							{ name: '사용가능', column: '1'}
							,{ name: '사용불가', column: '0'}
						]
					}
				]
			}
			, items: []
			, items_upper: []
			, is_modal_item: false
			, item_delete: { }
			, item_info: { }
		}
	}
	, computed: {

	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getSettingVirtualList'
					,data: this.search
				})
				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.search.page = page
			this.getData()
		}
		, onItem: function(){
			this.item_info = { }
			this.is_modal_item = true
		}
		, onItemDelete: function(item){
			if(confirm("해당 가상계좌 정보를 삭제하시겠습니까?")){
				this.postDelete(item)
			}
		}

		, postUpdate: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postVirtualItemUpdate'
					,data: {
						uid: item.uid
						, is_use: item.is_use
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
				this.is_confirm2 = false
			}
		}
		, postDelete: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postDeleteVirtual'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: '통신 오류' })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			console.log(item)
			this.is_modal_item = true
			this.item_info = item
		}
		, toList: function(item){
			this.$emit('push', { name: 'VirtualInfo', params: { idx: item.uid }})
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>